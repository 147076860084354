export enum AutomationTargetType {
  // Course related
  CourseSectionCompleted = 'course_section_completed',
  CourseSectionViewed = 'course_section_viewed',
  CourseCompletedPercentage = 'course_completed_percentage',
  CourseViewedPercentage = 'course_viewed_percentage',

  // Challenge related
  ChallengeEventAdded = 'challenge_event_added',
  ChallengeCompletedPercentage = 'challenge_completed_percentage',

  // Email related
  EmailConfirmed = 'email_confirmed',
  EmailMailOpened = 'email_mail_opened',
  EmailMailClicked = 'email_mail_clicked',

  // Campaign related
  CampaignMailOpened = 'campaign_mail_opened',
  CampaignMailClicked = 'campaign_mail_clicked',

  // Customer management
  ProductPurchased = 'purchased_product',
  TagAdded = 'tag_added',
  TagRemoved = 'tag_removed',
  ListAdded = 'list_added',
  ListRemoved = 'list_removed',
  Unsubscribed = 'unsubscribed',
  SubscriptionRenewal = 'subscription_renewal',

  // Forms
  FormSubmitted = 'form_submitted',
  FormFieldChecked = 'form_field_checked',

  // User types
  SubscriberCreated = 'subscriber_created',
  MemberCreated = 'member_created',
  AffiliateCreated = 'affiliate_created',

  // Platform
  PlatformAssigned = 'platform_assigned',
  IsTenantTrial = 'is_tenant_trial',
  WasCustomerTrial = 'was_customer_trial',
}

export interface AutomationBaseDefinition {
  name: string;
  target: AutomationTargetType;
  entity: string;
}
