import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { QuickbooksService } from './services/quickbooks.service';
import { AuthzHttpInterceptor } from './interceptors/authz-http.interceptor';
import { StripeService } from './services/stripe.service';
import { ActiveCampaignService } from './services/active-campaign.service';
import { DomainService } from './services/domain.service';
import { VimeoService } from './services/vimeo.service';
import { StoreModule } from '@ngrx/store';
import { AssetService } from './services/asset.service';
import { AnalyticsService } from './services/analytics.service';
import { CommunityService } from './services/community.service';
import { AffiliateService } from './services/affiliate.service';
import { MailerService } from './services/mailer.service';
import { ProfileService } from './services/profile.service';
import { CustomerService } from './services/customer.service';
import { WorkflowService } from './services/workflow.service';
import { QueryService } from './services/query.service';
import { CampaignService } from './services/campaign.service';
import { SendGridService } from './services/sendgrid.service';
import { SlackService } from './services/slack.service';
import { SchedulingService } from './services/scheduling.service';
import { ZoomService } from './services/zoom.service';
import { GoogleService } from './services/google.service';
import { UserService } from './services/user.service';
import { ShopService } from './services/shop.service';
import { AutomationService } from './services/automation.service';
@NgModule({
  imports: [CommonModule, StoreModule],
  providers: [
    QuickbooksService,
    StripeService,
    ActiveCampaignService,
    DomainService,
    VimeoService,
    AssetService,
    AnalyticsService,
    CommunityService,
    AffiliateService,
    MailerService,
    ProfileService,
    CustomerService,
    WorkflowService,
    QueryService,
    CampaignService,
    SendGridService,
    SlackService,
    SchedulingService,
    ZoomService,
    GoogleService,
    UserService,
    ShopService,
    AutomationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthzHttpInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class SidkikAPIModule {}
