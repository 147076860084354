<div *ngrxLet="{ filteredOptions: filteredOptions$, values: valueChange$} as data">
  @if (comboType === comboTypes.filtered) {
  <div class="tw-relative">
    <input autocomplete="off" (blur)="filterBlurred()" #filter id="combobox" type="text"
      [sidkikComboBoxTrigger]="combobox" class="input-placeholder-dark" [class.input-error]="error"
      [placeholder]="data.values | optionLookupLabel:(data.filteredOptions): defaultPlaceholder"
      (input)="inputFilterRequest($event)">
    @if (error) {
    <div class="input-error-icon" [sidkikTooltip]="errorMessage ?? ''">
      <sidkik-icon svgIcon="input_error"></sidkik-icon>
    </div>
    }
  </div>
  }

  <div *ngIf="comboType === comboTypes.lookup ">
    <button [sidkikComboBoxTrigger]="combobox" type="button"
      class="tw-relative tw-rounded-full tw-h-5 tw-w-5 tw-cursor-pointer tw-bg-transparent tw-text-left focus:tw-outline-none focus:tw-ring-1  sm:tw-text-sm"
      [class.input-error]="error" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
      <span class="tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center">
        <svg class="tw-h-5 tw-w-5 tw-text-gray-400" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
          stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
        </svg>
      </span>
      @if (error) {
      <div class="input-error-icon" [sidkikTooltip]="errorMessage ?? ''">
        <sidkik-icon svgIcon="input_error"></sidkik-icon>
      </div>
      }
    </button>
  </div>

  <div *ngIf="comboType === comboTypes.button || comboType === comboTypes.buttonPlus">
    <button [sidkikComboBoxTrigger]="combobox" type="button" [class.input-error]="error"
      class="input tw-relative tw-w-full tw-cursor-default tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-py-2 tw-pl-3 tw-pr-10 tw-text-left focus:tw-outline-none focus:tw-ring-1  sm:tw-text-sm"
      aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
      <span class="tw-block tw-truncate">{{data.values | optionLookupLabel:(data.filteredOptions):
        defaultPlaceholder}}</span>
      <span class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2">
        <!-- Heroicon name: mini/chevron-up-down -->
        <svg class="tw-h-5 tw-w-5 tw-text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd"
            d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
            clip-rule="evenodd" />
        </svg>
      </span>
      @if (error) {
      <div class="input-error-icon" [sidkikTooltip]="errorMessage ?? ''">
        <sidkik-icon svgIcon="input_error"></sidkik-icon>
      </div>
      }
    </button>
  </div>

  <div *ngIf="comboType === comboTypes.freeform" id="combobox" [class.input-error]="error"
    class="tw-min-h-[2.25rem] tw-pointer-events-none input tw-relative tw-w-full tw-cursor-default tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-py-2 tw-pl-3 tw-pr-10 tw-text-left  focus:tw-outline-none focus:tw-ring-1 ">
    <span *ngFor="let v of data.values"
      class="tw-inline-flex tw-items-center tw-rounded-full tw-bg-primary-100 tw-pt-0.5 tw-pb-1 tw-pl-2.5 tw-pr-1 tw-mb-1 tw-text-sm tw-font-medium tw-text-primary-700 tw-mr-2">
      {{ v | optionLookupLabel:(data.filteredOptions): defaultPlaceholder}}
      <button type="button" (click)="removeValue(v)" id="ignore"
        class="tw-pointer-events-auto tw-cursor-pointer tw-ml-0.5 tw-mt-0.5 tw-inline-flex tw-h-5 tw-w-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-text-primary-400 hover:tw-bg-primary-200 hover:tw-text-primary-500 focus:tw-bg-primary-500 focus:tw-text-white focus:tw-outline-none">
        <span class="tw-sr-only" id="ignore"></span>
        <svg class="tw-h-2 tw-w-2" id="ignore" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path id="ignore" stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
    <input (keydown)="checkKey($event)" (blur)="filterBlurred()" #filter id="combobox" type="text"
      [sidkikComboBoxTrigger]="combobox"
      class="tw-pointer-events-auto tw-w-full tw-pt-0 tw-pb-0 tw-bg-white tw-pr-12 tw-border-none tw-outline-none sm:tw-text-sm focus:tw-ring-0"
      [placeholder]="defaultPlaceholder" (input)="inputFilterRequest($event)">
    @if (error) {
    <div class="input-error-icon" [sidkikTooltip]="errorMessage ?? ''">
      <sidkik-icon svgIcon="input_error"></sidkik-icon>
    </div>
    }
  </div>

  <div *ngIf="comboType === comboTypes.chicklets" [sidkikComboBoxTrigger]="combobox" id="combobox"
    [class.input-error]="error"
    class="tw-min-h-[2.25rem] tw-pointer-events-none input tw-relative tw-w-full tw-cursor-default tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-py-2 tw-pl-3 tw-pr-10 tw-text-left focus:tw-ring-1  focus:tw-outline-none ">
    <span *ngFor="let v of data.values"
      class="tw-inline-flex tw-items-center tw-rounded-full tw-bg-primary-100 tw-pt-0.5 tw-pb-1 tw-pl-2.5 tw-pr-1 tw-mb-1 tw-text-sm tw-font-medium tw-text-primary-700 tw-mr-2">
      {{ v | optionLookupLabel:(data.filteredOptions): defaultPlaceholder}}
      <button type="button" (click)="removeValue(v)" id="ignore"
        class="tw-pointer-events-auto tw-cursor-pointer tw-ml-0.5 tw-inline-flex tw-mt-0.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-text-primary-400 hover:tw-bg-primary-200 hover:tw-text-primary-500 focus:tw-bg-primary-500 focus:tw-text-white focus:tw-outline-none">
        <span class="tw-sr-only" id="ignore"></span>
        <svg class="tw-h-2 tw-w-2" id="ignore" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path id="ignore" stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
    <span
      class="tw-h-auto tw-pointer-events-auto tw-cursor-pointer tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2">
      <!-- Heroicon name: mini/chevron-up-down -->
      <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke="currentColor"
        class="tw-w-7 tw-h-7 tw-text-gray-400">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </span>
    @if (error) {
    <div class="input-error-icon" [sidkikTooltip]="errorMessage ?? ''">
      <sidkik-icon svgIcon="input_error"></sidkik-icon>
    </div>
    }
  </div>
  <div *ngIf="comboType === comboTypes.filteredChicklets" id="combobox" [class.input-error]="error"
    class="tw-min-h-[2.31rem] tw-pointer-events-none input-chicklets tw-relative tw-w-full tw-cursor-default tw-rounded-md tw-border tw-border-gray-300 tw-bg-white tw-pl-3 tw-pr-10 tw-text-left focus:tw-ring-1  focus:tw-outline-none">
    <span *ngFor="let v of data.values"
      class="tw-inline-flex tw-items-center tw-gap-y-2 tw-rounded-full tw-mb-1 tw-bg-primary-100 tw-pt-0.5 tw-pb-0.5 tw-pl-2.5 tw-pr-1 tw-text-sm tw-font-medium tw-text-primary-700 tw-mr-2">
      {{ v | optionLookupLabel:(data.filteredOptions): defaultPlaceholder}}
      <button type="button" (click)="removeValue(v)" id="ignore"
        class="tw-pointer-events-auto tw-cursor-pointer tw-ml-0.5 tw-inline-flex tw-mt-0.5 tw-h-5 tw-w-5 tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-text-primary-400 hover:tw-bg-primary-200 hover:tw-text-primary-500 focus:tw-bg-primary-500 focus:tw-text-white focus:tw-outline-none">
        <span class="tw-sr-only" id="ignore"></span>
        <svg class="tw-h-2 tw-w-2" id="ignore" stroke="currentColor" fill="none" viewBox="0 0 8 8">
          <path id="ignore" stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>

    @if (error) {
    <div class="input-error-icon" [sidkikTooltip]="errorMessage ?? ''">
      <sidkik-icon svgIcon="input_error"></sidkik-icon>
    </div>
    }
    <input (blur)="filterBlurred()" #filter id="combobox" type="text" [sidkikComboBoxTrigger]="combobox"
      class="tw-pointer-events-auto tw-pt-1 tw-pb-0 tw-bg-white tw-px-2 tw-border-none tw-outline-none sm:tw-text-sm focus:tw-ring-0"
      [placeholder]="defaultPlaceholder" (input)="inputFilterRequest($event)">
  </div>
  <sidkik-combo-box-panel (changed)="changed($event)" #combobox [options]="data.filteredOptions" [comboType]="comboType"
    [multiSelect]="multiSelect" [value]="data.values" (filterBlurred)="filterBlurred()"
    (filterChanged)="inputFilterRequest($event)">
  </sidkik-combo-box-panel>
</div>