import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  AppConfig,
  APP_CONFIG,
  ANALYTICS_IS_SUPPORTED,
} from '../models/config';
import {
  Analytics,
  logEvent,
  setUserId as setUserIdFireAnalytics,
  setUserProperties,
} from '@angular/fire/analytics';
import { isPlatformServer } from '@angular/common';
import {
  newTracker,
  trackPageView,
  setUserId,
  TrackerConfiguration,
  addGlobalContexts,
  ConditionalContextProvider,
  ContextPrimitive,
  CommonEventProperties,
  PageViewEvent,
  trackSelfDescribingEvent,
  SelfDescribingEvent,
} from '@snowplow/browser-tracker';
import {
  Cart,
  CheckoutStep,
  CommonEcommerceEventProperties,
  ListClickEvent,
  ListViewEvent,
  Product,
  SnowplowEcommercePlugin,
  SPTransaction,
  trackAddToCart,
  trackCheckoutStep,
  trackProductListClick,
  trackProductListView,
  trackProductView,
  trackRemoveFromCart,
  trackTransaction,
  trackTransactionError,
  TransactionError,
} from '@snowplow/browser-plugin-snowplow-ecommerce';
import { GlobalFacade } from '../+state/global.facade';
import { Subject, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { AutoDestroy } from '@sidkik/shared';
import {
  CartItemProperties,
  OrderRequestProperties,
  PurchasableProperties,
} from '@sidkik/db';

export enum Category {
  Shop = 'shop',
  Course = 'course',
  Challenge = 'challenge',
  User = 'user',
}

export enum ShopAction {
  ViewItem = 'view_item',
  AddToCart = 'add_to_cart',
  RemoveFromCart = 'remove_from_cart',
  ViewCart = 'view_cart',
  BeginCheckout = 'begin_checkout',
  AddPaymentInfo = 'add_payment_info',
  Purchase = 'purchase',
}

export enum CourseAction {
  StartedCourse = 'started_course',
  CompletedCourse = 'completed_course',
  StartedSection = 'started_section',
  CompletedSection = 'completed_section',
  UndoCompletedSection = 'undo_completed_section',
}

export enum ChallengeAction {
  StartedChallenge = 'started_challenge',
  CompletedChallenge = 'completed_challenge',
  AddedChallengeActivity = 'added_challenge_activity',
  EditedChallengeActivity = 'edited_challenge_activity',
  RemovedChallengeActivity = 'removed_challenge_activity',
}

export enum CommunityAction {
  ViewedThread = 'viewed_thread',
  ViewedSpace = 'viewed_space',
  ViewedThreadList = 'viewed_thread_list',
  CommentedOnThread = 'commented_on_thread',
  ClappedThread = 'clapped_thread',
}

export enum UserAction {
  Login = 'login',
}

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  private readonly ANONYMOUS_TOKEN_KEY = 'anonymous_tracking_id';
  private readonly TOKEN_EXPIRY_DAYS = 7;

  @AutoDestroy()
  private onDestroy$ = new Subject<void>();

  customerId: string | undefined;
  jwt: string | undefined;
  customerIdSet = false;
  namespace = 'sidkik';
  schema = 'iglu:com.snowplowanalytics.snowplow/contexts/jsonschema/1-0-0';
  lastPath: string | undefined;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject('APP_VERSION') private appVersion: string,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(ANALYTICS_IS_SUPPORTED) private isSupported: boolean,
    private readonly globalFacade: GlobalFacade,
    private analytics: Analytics
  ) {
    this.globalFacade.jwt$.pipe(takeUntil(this.onDestroy$)).subscribe((jwt) => {
      this.jwt = jwt as string;
    });
    if (this.okToTrack()) {
      const spConfig: TrackerConfiguration = {
        contexts: {
          session: true,
          webPage: true,
          browser: true,
        },
        postPath: '/collector/se',
        // Custom function to add headers to each request
        credentials: 'omit',
        customFetch: async (request: Request, options?: RequestInit) => {
          logger.trace(
            'global:events.service:customFetch',
            'custom fetch',
            request
          );

          if (options) {
            options.headers = {
              ...options.headers,
              ...(await this.getSecurityHeaders()),
            };
          } else {
            options = {
              headers: await this.getSecurityHeaders(),
            };
          }

          return fetch(request, options);
        },
        plugins: [SnowplowEcommercePlugin()],
      };
      this.namespace = `sidkik-${this.appConfig?.telemetry?.trace?.serviceName}`;
      const endpoint = `${this.appConfig.api.endpoint}`;
      newTracker(this.namespace, endpoint, spConfig);
      this.setupGlobalContexts();
    }
  }

  private async getOrCreateAnonymousToken(): Promise<string> {
    const token = localStorage.getItem(this.ANONYMOUS_TOKEN_KEY);
    let tokenData;

    try {
      tokenData = token ? JSON.parse(token) : null;
    } catch {
      tokenData = null;
    }

    // Check if token exists and is still valid
    if (!tokenData || this.isTokenExpired(tokenData.createdAt)) {
      // Generate a new anonymous token with rate limiting info
      tokenData = {
        token: uuidv4(),
        createdAt: Date.now(),
        eventCount: 0,
      };
      localStorage.setItem(this.ANONYMOUS_TOKEN_KEY, JSON.stringify(tokenData));
    }

    return tokenData.token;
  }

  private isTokenExpired(createdAt: number): boolean {
    const expiryTime = createdAt + this.TOKEN_EXPIRY_DAYS * 24 * 60 * 60 * 1000;
    return Date.now() > expiryTime;
  }

  private generateContextSchema(context: string): string {
    // after /contexts/ insert the context
    return this.schema.replace('/contexts/', `/contexts/${context}/`);
  }

  private async getSecurityHeaders(): Promise<Record<string, string>> {
    logger.trace(
      'global:events.service:getSecurityHeaders',
      'getting security headers'
    );
    logger.trace('global:events.service:getSecurityHeaders', 'jwt', this.jwt);
    // If user is authenticated, use their JWT
    if (this.jwt) {
      return {
        Authorization: `Bearer ${this.jwt}`,
        'X-Client-ID': this.getOrCreateClientId(),
      };
    }

    // For anonymous users, use a temporary tracking token
    return {
      'X-Anonymous-Token': await this.getOrCreateAnonymousToken(),
      'X-Client-ID': this.getOrCreateClientId(),
    };
  }

  private getOrCreateClientId(): string {
    let clientId = localStorage.getItem('client_id');
    if (!clientId) {
      clientId = uuidv4();
      localStorage.setItem('client_id', clientId);
    }
    return clientId;
  }

  private setupGlobalContexts(): void {
    addGlobalContexts(
      {
        tenancy: {
          schema: this.generateContextSchema('tenancy'),
          data: {
            tenantId: this.appConfig.firebase.tenantId,
          },
        },
        appDetails: {
          schema: this.generateContextSchema('app_details'),
          data: {
            projectId: this.appConfig.firebase.projectId,
            appVersion: this.appVersion,
          },
        },
      } as Record<string, ConditionalContextProvider | ContextPrimitive>,
      [this.namespace]
    );
  }

  private setUserContexts(): void {
    if (!this.customerId) return;
    setUserId(this.customerId, [this.namespace]);
    addGlobalContexts(
      {
        user: {
          schema: this.generateContextSchema('user'),
          data: {
            customerId: this.customerId,
          },
        },
      },
      [this.namespace]
    );
    this.customerIdSet = true;
  }

  private okToTrack(): boolean {
    return this.isSupported && !isPlatformServer(this.platformId);
  }

  public trackScreenView(screen: string, path: string): void {
    if (!this.okToTrack()) return;
    if (path !== this.lastPath) {
      trackPageView(
        {
          title: screen,
          timestamp: Date.now(),
        } as PageViewEvent & CommonEventProperties,
        [this.namespace]
      );
      this.lastPath = path;
    }
    logEvent(this.analytics, 'screen_view' as any, {
      app_version: this.appVersion,
      screen_name: screen,
      firebase_screen: screen,
      page_path: path,
    });
  }

  public viewProductList(products: PurchasableProperties[]): void {
    if (!this.okToTrack()) return;
    console.log('global:events.service:viewProductList', 'products', products);
    trackProductListView(
      {
        name: 'product list',
        products: products.map((p) => ({
          id: p.data.product.id,
          price: p.data.sku[0].data.price,
          name: p.data.product.data.name,
          currency: 'USD',
          category: p.data.product.data.subtype,
        })),
      } as ListViewEvent & CommonEcommerceEventProperties,
      [this.namespace]
    );
  }

  public clickProductListItem(item: PurchasableProperties): void {
    if (!this.okToTrack()) return;
    trackProductListClick(
      {
        product: {
          id: item.data.product.id,
          price: item.data.sku[0].data.price,
          name: item.data.product.data.name,
          currency: 'USD',
          category: item.data.product.data.subtype,
        },
        name: 'product list',
      } as ListClickEvent & CommonEcommerceEventProperties,
      [this.namespace]
    );
  }

  public viewItem(item: PurchasableProperties): void {
    if (!this.okToTrack()) return;
    if (!!item && !!item.data && !!item.data.sku && !!item.data.sku[0]) {
      trackProductView(
        {
          id: item.data.product.id,
          price: item.data.sku[0].data.price,
          name: item.data.product.data.name,
          category: item.data.product.data.subtype,
          currency: 'USD',
        } as Product & CommonEcommerceEventProperties,
        [this.namespace]
      );
      logEvent(this.analytics, 'view_item' as any, {
        app_version: this.appVersion,
        currency: 'USD',
        value: this.convertToUSD(item.data.sku[0].data.price),
        items: [
          {
            item_id: item.data.product.id,
            item_name: item.data.product.data.name,
            price: this.convertToUSD(item.data.sku[0].data.price),
          },
        ],
      });
    }
  }

  // need to add the cart to the event with the cart total
  public addToCart(
    item: CartItemProperties,
    allItems: CartItemProperties[]
  ): void {
    if (!this.okToTrack()) return;
    if (!!item && !!item.data && !!item.data.sku) {
      let total = 0;
      if (allItems && allItems.length > 0) {
        total = allItems.reduce((acc, cur) => acc + cur.data.sku.data.price, 0);
      }
      trackAddToCart(
        {
          products: [
            {
              id: item.data.product.id,
              price: item.data.sku.data.price,
              name: item.data.product.data.name,
              category: item.data.product.data.subtype,
              currency: 'USD',
            },
          ],
          total_value: total,
          currency: 'USD',
        } as Cart & CommonEcommerceEventProperties,
        [this.namespace]
      );

      logEvent(this.analytics, 'add_to_cart' as any, {
        app_version: this.appVersion,
        currency: 'USD',
        value: this.convertToUSD(item.data.sku.data.price),
        items: [
          {
            item_id: item.data.product.id,
            item_name: item.data.product.data.name,
            price: this.convertToUSD(item.data.sku.data.price),
          },
        ],
      });
    }
  }

  // need to add the cart to the event with the cart total
  public removeFromCart(
    item: CartItemProperties,
    allItems: CartItemProperties[]
  ): void {
    if (!this.okToTrack()) return;
    if (!!item && !!item.data && !!item.data.sku) {
      let total = 0;
      if (allItems && allItems.length > 0) {
        total = allItems.reduce((acc, cur) => acc + cur.data.sku.data.price, 0);
      }
      trackRemoveFromCart(
        {
          products: [
            {
              id: item.data.product.id,
              name: item.data.product.data.name,
              category: item.data.product.data.subtype,
              price: item.data.sku.data.price,
              currency: 'USD',
            },
          ],
          total_value: total,
          currency: 'USD',
        },
        [this.namespace]
      );

      logEvent(this.analytics, 'remove_from_cart' as any, {
        app_version: this.appVersion,
        currency: 'USD',
        value: this.convertToUSD(item.data.sku.data.price),
        items: [
          {
            item_id: item.data.product.id,
            item_name: item.data.product.data.name,
            price: this.convertToUSD(item.data.sku.data.price),
          },
        ],
      });
    }
  }

  public viewCart(items: any[]): void {
    if (!this.okToTrack()) return;
    // trackSelfDescribingEvent(
    //   {
    //     event: {
    //       schema: this.schema,
    //       data: {
    //         value: items.reduce((acc, cur) => acc + cur.data.sku.data.price, 0),
    //         items: items.map((item) => ({
    //           itemId: item.data.product.id,
    //           itemName: item.data.product.data.name,
    //           price: item.data.sku.data.price,
    //         })),
    //         category: Category.Shop,
    //         action: ShopAction.ViewCart,
    //         timestamp: Date.now(),
    //       },
    //     },
    //   } as SelfDescribingEvent<Record<string, unknown>> &
    //     CommonEventProperties<Record<string, unknown>>,
    //   [this.namespace]
    // );

    logEvent(this.analytics, 'view_cart' as any, {
      app_version: this.appVersion,
      currency: 'USD',
      value: items.reduce((acc, cur) => acc + cur.data.sku.data.price, 0),
      items: items.map((item) => ({
        item_id: item.data.product.id,
        item_name: item.data.product.data.name,
        price: item.data.sku.data.price,
      })),
    });
  }

  public beginCheckout(items: any[]): void {
    if (!this.okToTrack()) return;
    trackCheckoutStep(
      {
        step: 1, // this is the landing on the checkout page (after clicking checkout)
      } as CheckoutStep & CommonEcommerceEventProperties,
      [this.namespace]
    );
    logEvent(this.analytics, 'begin_checkout' as any, {
      app_version: this.appVersion,
      currency: 'USD',
      value: items.reduce((acc, cur) => acc + cur.data.sku.data.price, 0),
      items: items.map((item) => ({
        item_id: item.data.product.id,
        item_name: item.data.product.data.name,
        price: item.data.sku.data.price,
      })),
    });
  }

  public applyCoupon(promoCode: string): void {
    if (!this.okToTrack()) return;
    trackCheckoutStep(
      {
        step: 2, // this is the application of the promo code (optional)
        coupon_code: promoCode,
      } as CheckoutStep & CommonEcommerceEventProperties,
      [this.namespace]
    );
  }

  public completePaymentInfo(paymentType: string): void {
    if (!this.okToTrack()) return;
    trackCheckoutStep(
      {
        step: 3, // this is the completion of the payment info
        payment_type: paymentType,
      } as CheckoutStep & CommonEcommerceEventProperties,
      [this.namespace]
    );
  }

  public addPaymentInfo(items: any[], total: number): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            value: total,
            items: items.map((item) => ({
              itemId: item.data.product.id,
              itemName: item.data.product.data.name,
              price: item.data.sku.data.price,
            })),
            category: Category.Shop,
            action: ShopAction.AddPaymentInfo,
            timestamp: Date.now(),
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );

    logEvent(this.analytics, 'add_payment_info' as any, {
      app_version: this.appVersion,
      currency: 'USD',
      value: total,
      items: items.map((item) => ({
        item_id: item.data.product.id,
        item_name: item.data.product.data.name,
        price: item.data.sku.data.price,
      })),
    });
  }

  public orderComplete(order: OrderRequestProperties, total: number): void {
    if (!this.okToTrack()) return;
    const discountCode = order.data.promotionCode?.data?.code;
    const paymentMethod =
      total > 0 ? order.data.paymentMethod?.data.brand ?? '' : 'free';
    const discountAmount = order.data.cart.data.items.reduce(
      (acc, cur) => acc + (cur.data.discountAmount ?? 0),
      0
    );

    const products: Product[] = order.data.cart.data.items.map((item) => ({
      id: item.data.product.id,
      price: item.data.sku.data.price,
      name: item.data.product.data.name,
      category: item.data.product.data.subtype,
      currency: 'USD',
    }));

    const transaction: SPTransaction & CommonEcommerceEventProperties = {
      transaction_id: order.id,
      revenue: total,
      currency: 'USD',
      payment_method: paymentMethod,
      products,
    };

    if (discountCode) {
      transaction.discount_code = discountCode;
    }

    if (discountAmount > 0) {
      transaction.discount_amount = discountAmount;
    }

    trackTransaction(transaction, [this.namespace]);
  }

  public orderIssue(order: OrderRequestProperties, total: number): void {
    if (!this.okToTrack()) return;
    const paymentMethod =
      total > 0 ? order.data.paymentMethod?.data.brand ?? '' : 'free';
    trackTransactionError(
      {
        error_description: order.integrations.state.message ?? 'unknown',
        error_type: 'hard',
        transaction: {
          transaction_id: order.id,
          revenue: total,
          currency: 'USD',
          payment_method: paymentMethod,
        },
      } as TransactionError & CommonEcommerceEventProperties,
      [this.namespace]
    );
  }

  public purchase(
    items: any[],
    total: number,
    coupon?: string | undefined
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            value: total,
            items: items.map((item) => ({
              itemId: item.data.product.id,
              itemName: item.data.product.data.name,
              price: item.data.sku.data.price,
            })),
            category: Category.Shop,
            action: ShopAction.Purchase,
            timestamp: Date.now(),
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );

    logEvent(this.analytics, 'purchase' as any, {
      app_version: this.appVersion,
      coupon,
      currency: 'USD',
      value: this.convertToUSD(total),
      items: items.map((item) => ({
        item_id: item.data.product.id,
        item_name: item.data.product.data.name,
        price: this.convertToUSD(item.data.sku.data.price),
      })),
    });
  }

  public setUser(uid: string, email?: string): void {
    this.customerId = uid;
    this.setUserContexts();
    if (!this.okToTrack()) return;
    setUserIdFireAnalytics(this.analytics, uid);
    setUserProperties(this.analytics, {
      app_version: this.appVersion,
      // sid_email: email,
    });
  }

  public trackLogin(): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.User,
            action: UserAction.Login,
            timestamp: Date.now(),
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
    logEvent(this.analytics, 'login' as any, {
      app_version: this.appVersion,
    });
  }

  public trackCourseStart(courseId: string): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Course,
            action: CourseAction.StartedCourse,
            timestamp: Date.now(),
            courseId,
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackCourseCompletion(courseId: string): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Course,
            action: CourseAction.CompletedCourse,
            timestamp: Date.now(),
            courseId,
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackCourseSectionStart(
    courseId: string,
    sectionId: string,
    percentageStarted: number
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Course,
            action: CourseAction.StartedSection,
            timestamp: Date.now(),
            courseId,
            sectionId,
            percentageStarted,
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackCourseSectionCompletion(
    courseId: string,
    sectionId: string,
    percentageCompleted: number
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Course,
            action: CourseAction.CompletedSection,
            timestamp: Date.now(),
            courseId,
            sectionId,
            percentageCompleted: Math.floor(percentageCompleted),
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackCourseSectionCompletionUndo(
    courseId: string,
    sectionId: string,
    percentageCompleted: number
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Course,
            action: CourseAction.UndoCompletedSection,
            timestamp: Date.now(),
            courseId,
            sectionId,
            percentageCompleted: Math.floor(percentageCompleted),
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackChallengeStart(
    challengeId: string,
    challengeProgressId: string,
    numberOfActivities: number
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Challenge,
            action: ChallengeAction.StartedChallenge,
            timestamp: Date.now(),
            challengeId,
            numberOfActivities,
            challengeProgressId,
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackAddChallengeActivity(
    challengeId: string,
    challengeProgressId: string,
    activityId: string,
    numberOfActivities: number,
    when: number,
    sentiments: any[],
    percentageCompleted: number
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Challenge,
            action: ChallengeAction.AddedChallengeActivity,
            timestamp: Date.now(),
            challengeId,
            numberOfActivities,
            when,
            sentiments,
            activityId,
            challengeProgressId,
            percentageCompleted,
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackEditChallengeActivity(
    challengeId: string,
    challengeProgressId: string,
    activityId: string,
    numberOfActivities: number,
    when: number,
    sentiments: any[],
    percentageCompleted: number
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Challenge,
            action: ChallengeAction.EditedChallengeActivity,
            timestamp: Date.now(),
            challengeId,
            numberOfActivities,
            when,
            sentiments,
            activityId,
            challengeProgressId,
            percentageCompleted,
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  public trackRemoveChallengeActivity(
    challengeId: string,
    challengeProgressId: string,
    activityId: string,
    numberOfActivities: number,
    percentageCompleted: number
  ): void {
    if (!this.okToTrack()) return;
    trackSelfDescribingEvent(
      {
        event: {
          schema: this.schema,
          data: {
            category: Category.Challenge,
            action: ChallengeAction.RemovedChallengeActivity,
            timestamp: Date.now(),
            challengeId,
            numberOfActivities,
            activityId,
            challengeProgressId,
            percentageCompleted,
          },
        },
      } as SelfDescribingEvent<Record<string, unknown>> &
        CommonEventProperties<Record<string, unknown>>,
      [this.namespace]
    );
  }

  private convertToUSD(amount: number) {
    return amount * 0.01;
  }
}
