import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import { DropdownPanel } from './dropdown-panel';
import {
  HorizontalConnectionPos,
  Overlay,
  OverlayRef,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { delay, merge, Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[sidkikDropdownTrigger],[sidkik-dropdown-trigger]',
  exportAs: 'dropdownTrigger',
})
export class DropdownTriggerDirective implements OnDestroy {
  @HostBinding('class.open') private isDropdownOpen = false;

  private overlayRef!: OverlayRef;
  private dropdownClosingActionsSub = Subscription.EMPTY;
  private dropdownOutsideEventsActionsSub = Subscription.EMPTY;

  @Input('sidkikDropdownTrigger') public dropdownPanel!: DropdownPanel;

  @HostListener('click') onClick() {
    this.toggleDropdown();
  }
  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef<HTMLElement>,
    private viewContainerRef: ViewContainerRef
  ) {}

  toggleDropdown(): void {
    this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
  }

  openDropdown(): void {
    const [originX, originFallbackX]: HorizontalConnectionPos[] =
      this.dropdownPanel.xPosition === 'before'
        ? ['end', 'start']
        : ['start', 'end'];

    const [overlayY, overlayFallbackY]: VerticalConnectionPos[] =
      this.dropdownPanel.yPosition === 'above'
        ? ['bottom', 'top']
        : ['top', 'bottom'];

    const [originY, originFallbackY] = [overlayY, overlayFallbackY];
    const [overlayX, overlayFallbackX] = [originX, originFallbackX];
    const offsetY = 30;

    this.isDropdownOpen = true;
    this.overlayRef = this.overlay.create({
      hasBackdrop: this.dropdownPanel.hasBackdrop,
      backdropClass: this.dropdownPanel.backdropClass,
      panelClass: 'cdk-overlay-pane-absolute-dd',
      scrollStrategy: this.overlay.scrollStrategies.reposition({
        autoClose: true,
      }),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          { originX, originY, overlayX, overlayY, offsetY },
          {
            originX: originFallbackX,
            originY,
            overlayX: overlayFallbackX,
            overlayY,
            offsetY,
          },
          {
            originX,
            originY: originFallbackY,
            overlayX,
            overlayY: overlayFallbackY,
            offsetY: -offsetY,
          },
          {
            originX: originFallbackX,
            originY: originFallbackY,
            overlayX: overlayFallbackX,
            overlayY: overlayFallbackY,
            offsetY: -offsetY,
          },
        ]),
    });

    const templatePortal = new TemplatePortal(
      this.dropdownPanel.templateRef,
      this.viewContainerRef
    );
    this.overlayRef.attach(templatePortal);

    this.dropdownOutsideEventsActionsSub = this.overlayRef
      .outsidePointerEvents()
      .pipe(delay(50)) // allow the toggle to fire before the mouse event if the mouseevent is from the trigger
      .subscribe(() => {
        this.destroyDropdown();
      });
    this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe(
      () => {
        this.destroyDropdown();
      }
    );
  }

  private dropdownClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this.overlayRef.backdropClick();
    const detachment$ = this.overlayRef.detachments();
    const dropdownClosed = this.dropdownPanel.closed;

    return merge(backdropClick$, detachment$, dropdownClosed);
  }

  private destroyDropdown(): void {
    if (!this.overlayRef || !this.isDropdownOpen) {
      return;
    }

    this.dropdownClosingActionsSub.unsubscribe();
    this.isDropdownOpen = false;
    this.overlayRef.detach();
  }

  ngOnDestroy(): void {
    this.dropdownClosingActionsSub.unsubscribe();
    this.dropdownOutsideEventsActionsSub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }
}
