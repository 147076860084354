import { SpanKind } from '@opentelemetry/api';

const localFireStore = 'client-firestore';
const stripe = 'stripe_api';
const intent = 'intent';
const admin = 'admin';
const firebase = 'firebase';
const community = 'community';

export interface SpanType {
  name: string;
  service?: string;
  kind?: SpanKind;
}
// export interface SpanTypes {
//   [key: string]: SpanType;
// }

export const SpanTypes = {
  errorReporter: {
    name: 'error.Reporter',
    kind: SpanKind.SERVER,
  },
  firebaseRefreshToken: {
    name: 'firebase.RefreshToken',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  firebaseGetToken: {
    name: 'firebase.GetToken',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  firebaseResetPassword: {
    name: 'firebase.ResetPassword',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  firebaseSigninWith: {
    name: 'firebase.SigninWith',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  firebaseSignup: {
    name: 'firebase.Signup',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  firebaseSignout: {
    name: 'firebase.Signout',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  firebaseUpdateProfileName: {
    name: 'firebase.UpdateProfileName',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  firebaseUpdateProfilePicture: {
    name: 'firebase.UpdateProfilePicture',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  firebaseUpdatePassword: {
    name: 'firebase.UpdatePassword',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  firebaseConfirmPasswordReset: {
    name: 'firebase.ConfirmPasswordReset',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  firebaseUploadUserStorage: {
    name: 'firebase.UploadUserStorage',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  firebaseUploadOrderSnapshot: {
    name: 'firebase.UploadOrderSnapshot',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  firebaseDeleteUserStorage: {
    name: 'firebase.DeleteUserStorage',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  firebaseDeleteTenantStorage: {
    name: 'firebase.DeleteTenantStorage',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  firebaseGetDownloadUrl: {
    name: 'firebase.GetDownloadUrl',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  firebaseUploadTenantStorage: {
    name: 'firebase.UploadTenantStorage',
    // service: firebase,
    kind: SpanKind.SERVER,
  },
  queryCustomer: {
    name: 'query.Customer',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  shopGetAvailabilityByProduct: {
    name: 'shop.GetAvailabilityByProduct',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminGetAvailability: {
    name: 'admin.GetAvailability',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminGetAvailabilityByYearMonth: {
    name: 'admin.GetAvailabilityByYearMonth',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminUpdateAvailability: {
    name: 'admin.UpdateAvailability',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  queryGetCampaignRecipientsCount: {
    name: 'query.GetCampaignRecipientsCount',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  queryGetListsRecipientCount: {
    name: 'query.GetListsRecipientCount',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminStartCampaignRun: {
    name: 'admin.adminStartCampaignRun',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminStopCampaignRun: {
    name: 'admin.adminStopCampaignRun',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminUpdateWorkflowState: {
    name: 'admin.UpdateWorkflowState',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminUpdateAutomationState: {
    name: 'admin.UpdateAutomationState',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  sendGridConfigureWebhooks: {
    name: 'sendgrid.ConfigureWebhooks',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  sendGridGetStats: {
    name: 'sendgrid.GetStats',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminSlackGetConfig: {
    name: 'admin.GetSlackConfig',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminSlackGetAuthUri: {
    name: 'admin.GetSlackAuthUri',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminSlackSetChannels: {
    name: 'admin.SetSlackChannels',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminSlackGetChannels: {
    name: 'admin.GetSlackChannels',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminSlackSendTestMessage: {
    name: 'admin.SendSlackTestMessage',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminSlackGetConnected: {
    name: 'admin.GetSlackConnected',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminVimeoGetConfig: {
    name: 'admin.GetVimeoConfig',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminVimeoGetAuthUri: {
    name: 'admin.GetVimeoAuthUri',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminVimeoGetConnected: {
    name: 'admin.GetVimeoConnected',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminZoomGetConfig: {
    name: 'admin.GetZoomConfig',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminZoomGetAuthUri: {
    name: 'admin.GetZoomAuthUri',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminZoomDisconnect: {
    name: 'admin.DisconnectZoom',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminZoomGetConnected: {
    name: 'admin.GetZoomConnected',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminGoogleGetAuthUri: {
    name: 'admin.GetGoogleAuthUri',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminGoogleGetConnected: {
    name: 'admin.GetGoogleConnected',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminGoogleGetConfig: {
    name: 'admin.GetGoogleConfig',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminUpdatePermissions: {
    name: 'admin.UpdatePermissions',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminCustomerInvite: {
    name: 'admin.InviteCustomer',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminECVerify: {
    name: 'admin.VerifyEmail',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminDOIVerify: {
    name: 'admin.VerifyDoubleOptIn',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminUnsubscribeVerify: {
    name: 'admin.adminUnsubscribeVerify',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminMarkDoNotContact: {
    name: 'admin.MarkDoNotContactForCustomer',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminUnmarkDoNotContact: {
    name: 'admin.UnmarkDoNotContactForCustomer',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminBulkAddTags: {
    name: 'admin.BulkAddTagsToCustomers',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminBulkRemoveTags: {
    name: 'admin.BulkRemoveTagsFromCustomers',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminBulkAddLists: {
    name: 'admin.BulkAddListsToCustomers',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminBulkRemoveLists: {
    name: 'admin.BulkRemoveListsFromCustomers',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminAddTag: {
    name: 'admin.AddTagToCustomer',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminRemoveTag: {
    name: 'admin.RemoveTagFromCustomer',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminAddList: {
    name: 'admin.AddListToCustomer',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminRemoveList: {
    name: 'admin.RemoveListFromCustomer',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminMailerGetConfig: {
    name: 'admin.GetMailerConfig',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminMailerUpdateConfig: {
    name: 'admin.UpdateMailerConfig',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminSendTestEmail: {
    name: 'admin.SendTestEmail',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminACGetConfig: {
    name: 'admin.GetActiveCampaignConfig',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminACUpdateConfig: {
    name: 'admin.UpdateActiveCampaignConfig',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminACImportCustomer: {
    name: 'admin.ImportActiveCampaignCustomer',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminACImportOrder: {
    name: 'admin.ImportActiveCampaignOrder',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminACGetConnection: {
    name: 'admin.GetActiveCampaignConnection',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminACTestConnection: {
    name: 'admin.TestActiveCampaignConnection',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminACCreateConnection: {
    name: 'admin.CreateActiveCampaignConnection',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminAnalyticsGetBasicWeb: {
    name: 'admin.GetBasicWebAnalytics',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminAssetsMarkUploaded: {
    name: 'admin.MarkAssetsUploaded',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminAssetSync: {
    name: 'admin.SyncAsset',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminUpdateTheme: {
    name: 'admin.UpdateTheme',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminUpdateAddon: {
    name: 'admin.UpdateAddon',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminAddSubdomain: {
    name: 'admin.AddSubdomain',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminAddCustomDomain: {
    name: 'admin.AddCustomDomain',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminVerifyCustomDomain: {
    name: 'admin.VerifyCustomDomain',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminRequestProvisionForCustomDomain: {
    name: 'admin.RequestProvisionForCustomDomain',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminDeleteSubdomain: {
    name: 'admin.DeleteSubdomain',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminDeleteCustomDomain: {
    name: 'admin.DeleteCustomDomain',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  affiliateRequestNew: {
    name: 'admin.RequestNewAffiliate',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  affiliatePay: {
    name: 'admin.PayAffiliate',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  affiliateProcessApproval: {
    name: 'admin.ProcessAffiliateApproval',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  affiliateConnectURL: {
    name: 'admin.RequestConnectURLForAffiliate',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  communityRequestThreadPublish: {
    name: 'community.RequestThreadPublish',
    // service: community,
    kind: SpanKind.SERVER,
  },
  communityRequestThreadApplaud: {
    name: 'community.RequestThreadApplaud',
    // service: community,
    kind: SpanKind.SERVER,
  },
  communityRequestThreadUncordon: {
    name: 'community.RequestThreadUncordon',
    // service: community,
    kind: SpanKind.SERVER,
  },
  communityRequestThreadReject: {
    name: 'community.RequestThreadReject',
    // service: community,
    kind: SpanKind.SERVER,
  },
  communityRequestCommentPublish: {
    name: 'community.RequestCommentPublish',
    // service: community,
    kind: SpanKind.SERVER,
  },
  communityRequestCommentApplaud: {
    name: 'community.RequestCommentApplaud',
    // service: community,
    kind: SpanKind.SERVER,
  },
  communityRequestCommentReport: {
    name: 'community.RequestCommentReport',
    // service: community,
    kind: SpanKind.SERVER,
  },
  communityRequestCommentUncordon: {
    name: 'community.RequestCommentUncordon',
    // service: community,
    kind: SpanKind.SERVER,
  },
  communityRequestCommentReject: {
    name: 'community.RequestCommentReject',
    // service: community,
    kind: SpanKind.SERVER,
  },
  communityRequestCommentIgnore: {
    name: 'community.RequestCommentIgnore',
    // service: community,
    kind: SpanKind.SERVER,
  },
  communityRequestCommentRemove: {
    name: 'community.RequestCommentRemove',
    // service: community,
    kind: SpanKind.SERVER,
  },
  profileUpdateName: {
    name: 'profile.UpdateDisplayName',
    // service: community,
    kind: SpanKind.SERVER,
  },
  profileUpdateAvatar: {
    name: 'profile.UpdateAvatar',
    // service: community,
    kind: SpanKind.SERVER,
  },
  adminEnableGoogleCalendarSync: {
    name: 'admin.EnableGoogleCalendarSync',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminDisableGoogleCalendarSync: {
    name: 'admin.DisableGoogleCalendarSync',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminQBGetVendors: {
    name: 'admin.GetQuickbooksVendors',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminQBGetAccounts: {
    name: 'admin.GetQuickbooksAccounts',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminQBCreateAccount: {
    name: 'admin.CreateQuickbooksAccount',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminQBGetAuthUri: {
    name: 'admin.GetQuickbooksAuthUri',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminStripeGetAccount: {
    name: 'admin.GetStripeAccount',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminStripeGetBalance: {
    name: 'admin.GetStripeBalance',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminStripeFundAccount: {
    name: 'admin.FundStripeAccount',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminStripeGetOAuthLink: {
    name: 'admin.GetStripeOAuthLink',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  adminStripeUpsertConnectKey: {
    name: 'admin.UpsertConnectKey',
    // service: admin,
    kind: SpanKind.SERVER,
  },
  shopCheckPromoCode: {
    name: 'shop.CheckPromoCode',
    // service: intent,
    kind: SpanKind.SERVER,
  },
  paymentAddPaymentMethod: {
    name: 'payment.AddPaymentMethod',
    kind: SpanKind.INTERNAL,
  },
  paymentSetupPaymentintent: {
    name: 'payment.SetupPaymentintent',
    // service: intent,
    kind: SpanKind.SERVER,
  },
  paymentStorePaymentintent: {
    name: 'payment.StorePaymentintent',
    service: localFireStore,
    kind: SpanKind.SERVER,
  },
  paymentMonitorPaymentintent: {
    name: 'payment.MonitorPaymentintent',
    service: localFireStore,
    kind: SpanKind.SERVER,
  },
  stripeConfirmCard: {
    name: 'stripe.ConfirmCard',
    service: stripe,
    kind: SpanKind.SERVER,
  },
  stripePaymentMethod: {
    name: 'stripe.CreatePaymentMethod',
    service: stripe,
    kind: SpanKind.SERVER,
  },
  shopVerifyCoupon: {
    name: 'shop.VerifyCoupon',
    kind: SpanKind.SERVER,
  },
  shopProcessOrder: {
    name: 'shop.ProcessOrder',
    kind: SpanKind.SERVER,
  },
  shopPayOrder: {
    name: 'shop.PayOrder',
    kind: SpanKind.SERVER,
  },
  shopStoreOrderRequest: {
    name: 'shop.StoreOrderRequest',
    service: localFireStore,
    kind: SpanKind.SERVER,
  },
  shopMonitorOrderRequest: {
    name: 'shop.MonitorOrderRequest',
    service: localFireStore,
    kind: SpanKind.SERVER,
  },
  shopMonitorPermissions: {
    name: 'shop.MonitorPermissions',
    service: localFireStore,
    kind: SpanKind.SERVER,
  },
};
