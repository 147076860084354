import {
  ComboBoxOption,
  MenuEntryDisplay,
  NavigationEntry,
} from '../../models';

export const lookupNoneSelected = { value: '', label: 'None Selected' };

export enum EmailType {
  DoubleOptIn = 'DoubleOptIn',
  Transactional = 'Transactional',
  Marketing = 'Marketing',
  EmailConfirmation = 'EmailConfirmation',
}

export enum CustomerType {
  Subscriber = 'Subscriber',
  Member = 'Member',
}

export enum PageType {
  Landing = 'Landing',
  Home = 'Home',
  Site = 'Site',
  PrivacyPolicy = 'PrivacyPolicy',
  TermsOfUse = 'TermsOfUse',
}

export enum NavigationType {
  Minimal = 'Minimal',
  Full = 'Full',
  None = 'None',
}

export enum StaticSlug {
  PrivacyPolicy = 'privacy-policy',
  TermsOfUse = 'terms-of-use',
}

export enum AffiliatePayoutType {
  Stripe = 'Stripe',
  Offline = 'Offline',
}

export enum ShopSections {
  Sessions = 'Sessions',
  Memberships = 'Memberships',
  CoursesAndChallenges = 'CoursesAndChallenges',
  Platforms = 'Platforms',
  PlatformTrials = 'PlatformTrials',
}

export enum MyActivitiesSections {
  Flows = 'Flows',
  Sessions = 'Sessions',
  Memberships = 'Memberships',
  Courses = 'Courses',
  Challenges = 'Challenges',
  Platforms = 'Platforms',
  PlatformTrials = 'PlatformTrials',
}

export const ShopSectionsLookup: ComboBoxOption[] = [
  { value: ShopSections.Sessions, label: 'Sessions' },
  { value: ShopSections.Memberships, label: 'Memberships' },
  { value: ShopSections.CoursesAndChallenges, label: 'Courses and Challenges' },
  { value: ShopSections.Platforms, label: 'Platforms' },
  { value: ShopSections.PlatformTrials, label: 'Platform Trials' },
];

export const MyActivitiesSectionsLookup: ComboBoxOption[] = [
  { value: MyActivitiesSections.Flows, label: 'Flows' },
  { value: MyActivitiesSections.Sessions, label: 'Sessions' },
  { value: MyActivitiesSections.Memberships, label: 'Memberships' },
  { value: MyActivitiesSections.Courses, label: 'Courses' },
  { value: MyActivitiesSections.Challenges, label: 'Challenges' },
  { value: MyActivitiesSections.Platforms, label: 'Platforms' },
  { value: MyActivitiesSections.PlatformTrials, label: 'Platform Trials' },
];

export enum ItemsDisplayType {
  List = 'List',
  Tabs = 'Tabs',
}

export enum StaticMenuEntry {
  Cart = 'Cart',
  SignUp = 'SignUp',
  SignIn = 'SignIn',
  MyActivities = 'MyActivities',
}

export const StaticHideableMenuEntries: StaticMenuEntry[] = [
  StaticMenuEntry.SignUp,
  StaticMenuEntry.SignIn,
];

export const DefaultStaticMenuEntries: NavigationEntry[] = [
  {
    title: 'My Activities',
    url: '/my-activities',
    staticId: StaticMenuEntry.MyActivities,
    display: MenuEntryDisplay.Link,
  },
  {
    title: 'Cart',
    url: '/shop/cart',
    staticId: StaticMenuEntry.Cart,
  },
  {
    title: 'Sign Up',
    url: '/auth/register',
    staticId: StaticMenuEntry.SignUp,
    display: MenuEntryDisplay.None,
  },
  {
    title: 'Sign In',
    url: '/auth/login',
    staticId: StaticMenuEntry.SignIn,
    display: MenuEntryDisplay.ButtonPrimary,
  },
];

export const ItemsDisplayTypeLookup: ComboBoxOption[] = [
  {
    value: ItemsDisplayType.List,
    label: 'List',
    description: 'Vertical list divided into sections',
  },
  {
    value: ItemsDisplayType.Tabs,
    label: 'Tabs',
    description: 'Horizontal tabs with content below',
  },
];

export const DefaultShopItemsDisplayType = ItemsDisplayType.List;
export const DefaultMyActivitiesItemsDisplayType = ItemsDisplayType.List;
export const DefaultShopSections = [
  ShopSections.CoursesAndChallenges,
  ShopSections.Sessions,
  ShopSections.Memberships,
];

export const DefaultMyActivitiesSections = [
  MyActivitiesSections.Courses,
  MyActivitiesSections.Challenges,
  MyActivitiesSections.Sessions,
  MyActivitiesSections.Memberships,
];
