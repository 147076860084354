import { ComboBoxOption } from '../ui';

export enum AutomationOperatorLiterals {
  True = 'true',
  False = 'false',
  StringExists = 'stringexists',
  StringDoesNotExist = 'stringdoesnotexist',
}

export type AutomationOperator = ComboBoxOption;

export enum AutomationBaseOperatorType {
  Equals = 'eq',
  NotEquals = 'ne',
  GreaterThan = 'gt',
  GreaterThanOrEquals = 'ge',
  LessThan = 'lt',
  LessThanOrEquals = 'le',
  In = 'in',
  NotIn = 'notIn',
  InAll = 'inAll',
  After = 'after',
  Before = 'before',
  Exists = 'exists',
  DoesNotExist = 'doesNotExist',
  IsTrue = 'isTrue',
  IsFalse = 'isFalse',
  GreaterThanOrEqualsInAll = 'geInAll',
  LessThanOrEqualsInAll = 'leInAll',
  GreaterThanInAll = 'gtInAll',
  LessThanInAll = 'ltInAll',
  GreaterThanOrEqualsIn = 'geIn',
  LessThanOrEqualsIn = 'leIn',
  GreaterThanIn = 'gtIn',
  LessThanIn = 'ltIn',
  GreaterThanOrEqualsInNone = 'geInNone',
  LessThanOrEqualsInNone = 'leInNone',
  GreaterThanInNone = 'gtInNone',
  LessThanInNone = 'ltInNone',
}

export enum AutomationOperatorType {
  UnSelected = 'unSelected',
  In = 'in',
  InAll = 'inAll',
  NotIn = 'notIn',
  EQ = 'eq',
  NE = 'ne',
  GT = 'gt',
  GTInAll = 'gtInAll',
  GTIn = 'gtIn',
  GTInNone = 'gtInNone',
  GE = 'ge',
  GEInAll = 'geInAll',
  GEIn = 'geIn',
  GEInNone = 'geInNone',
  LT = 'lt',
  LTInAll = 'ltInAll',
  LTIn = 'ltIn',
  LTInNone = 'ltInNone',
  LE = 'le',
  LEInAll = 'leInAll',
  LEIn = 'leIn',
  LEInNone = 'leInNone',
  IsTrue = 'isTrue',
  IsFalse = 'isFalse',
  Yes = 'yes',
  No = 'no',
  IsSubscribedToAny = 'isSubscribedToAny',
  IsSubscribedToAll = 'isSubscribedToAll',
  IsNotSubscribedTo = 'isNotSubscribedTo',
  HasAny = 'hasAny',
  HasAll = 'hasAll',
  DoesNotHave = 'doesNotHave',
  OwnsAny = 'ownsAny',
  OwnsAll = 'ownsAll',
  DoesNotOwn = 'doesNotOwn',
  SpentMoreThan = 'spentMoreThan',
  SpentLessThan = 'spentLessThan',
  StartedAll = 'startedAll',
  StartedAny = 'startedAny',
  StartedNone = 'startedNone',
  SubmittedAll = 'submittedAll',
  SubmittedAny = 'submittedAny',
  SubmittedNone = 'submittedNone',
  IsType = 'isType',
  IsNotType = 'isNotType',
  EqualsAllChecked = 'equalsAllChecked',
  EqualsAnyChecked = 'equalsAnyChecked',
  EqualsNoneChecked = 'equalsNoneChecked',
  Before = 'before',
  After = 'after',
  Exists = 'stringexists',
  DoesNotExist = 'stringdoesnotexist',
  OpenedAny = 'openedAny',
  OpenedAll = 'openedAll',
  OpenedNone = 'openedNone',
  ClickedAny = 'clickedAny',
  ClickedAll = 'clickedAll',
  ClickedNone = 'clickedNone',
}

export const AutomationOperatorComboBoxOptions: Record<
  AutomationOperatorType,
  AutomationOperator
> = {
  [AutomationOperatorType.After]: {
    value: AutomationOperatorType.After,
    label: 'After',
  },
  [AutomationOperatorType.Before]: {
    value: AutomationOperatorType.Before,
    label: 'Before',
  },
  [AutomationOperatorType.UnSelected]: {
    value: AutomationOperatorType.UnSelected,
    label: 'Unselected',
  },
  [AutomationOperatorType.In]: {
    value: AutomationOperatorType.In,
    label: 'In',
  },
  [AutomationOperatorType.InAll]: {
    value: AutomationOperatorType.InAll,
    label: 'In All',
  },
  [AutomationOperatorType.NotIn]: {
    value: AutomationOperatorType.NotIn,
    label: 'Not In',
  },
  [AutomationOperatorType.EQ]: {
    value: AutomationOperatorType.EQ,
    label: 'Equals',
  },
  [AutomationOperatorType.NE]: {
    value: AutomationOperatorType.NE,
    label: 'Not Equals',
  },
  [AutomationOperatorType.GT]: {
    value: AutomationOperatorType.GT,
    label: 'Greater Than',
  },
  [AutomationOperatorType.GTInAll]: {
    value: AutomationOperatorType.GTInAll,
    label: 'Greater Than In All',
  },
  [AutomationOperatorType.GTIn]: {
    value: AutomationOperatorType.GTIn,
    label: 'Greater Than In Any',
  },
  [AutomationOperatorType.GTInNone]: {
    value: AutomationOperatorType.GTInNone,
    label: 'Greater Than In None',
  },
  [AutomationOperatorType.GE]: {
    value: AutomationOperatorType.GE,
    label: 'Greater Than or Equals',
  },
  [AutomationOperatorType.GEInAll]: {
    value: AutomationOperatorType.GEInAll,
    label: 'Greater Than or Equals In All',
  },
  [AutomationOperatorType.GEIn]: {
    value: AutomationOperatorType.GEIn,
    label: 'Greater Than or Equals In Any',
  },
  [AutomationOperatorType.GEInNone]: {
    value: AutomationOperatorType.GEInNone,
    label: 'Greater Than or Equals In None',
  },
  [AutomationOperatorType.LT]: {
    value: AutomationOperatorType.LT,
    label: 'Less Than',
  },
  [AutomationOperatorType.LTInAll]: {
    value: AutomationOperatorType.LTInAll,
    label: 'Less Than In All',
  },
  [AutomationOperatorType.LTIn]: {
    value: AutomationOperatorType.LTIn,
    label: 'Less Than In Any',
  },
  [AutomationOperatorType.LTInNone]: {
    value: AutomationOperatorType.LTInNone,
    label: 'Less Than In None',
  },
  [AutomationOperatorType.LE]: {
    value: AutomationOperatorType.LE,
    label: 'Less Than or Equals',
  },
  [AutomationOperatorType.LEInAll]: {
    value: AutomationOperatorType.LEInAll,
    label: 'Less Than or Equals In All',
  },
  [AutomationOperatorType.LEIn]: {
    value: AutomationOperatorType.LEIn,
    label: 'Less Than or Equals In Any',
  },
  [AutomationOperatorType.LEInNone]: {
    value: AutomationOperatorType.LEInNone,
    label: 'Less Than or Equals In None',
  },
  [AutomationOperatorType.IsTrue]: {
    value: AutomationOperatorType.IsTrue,
    label: 'Is True',
  },
  [AutomationOperatorType.IsFalse]: {
    value: AutomationOperatorType.IsFalse,
    label: 'Is False',
  },
  [AutomationOperatorType.Yes]: {
    value: AutomationOperatorType.IsTrue,
    label: 'Yes',
  },
  [AutomationOperatorType.No]: {
    value: AutomationOperatorType.IsFalse,
    label: 'No',
  },
  [AutomationOperatorType.IsSubscribedToAny]: {
    value: AutomationOperatorType.In,
    label: 'Is Subscribed To Any',
  },
  [AutomationOperatorType.IsSubscribedToAll]: {
    value: AutomationOperatorType.InAll,
    label: 'Is Subscribed To All',
  },
  [AutomationOperatorType.IsNotSubscribedTo]: {
    value: AutomationOperatorType.NotIn,
    label: 'Is Not Subscribed To',
  },
  [AutomationOperatorType.HasAny]: {
    value: AutomationOperatorType.In,
    label: 'Has Any',
  },
  [AutomationOperatorType.HasAll]: {
    value: AutomationOperatorType.InAll,
    label: 'Has All',
  },
  [AutomationOperatorType.DoesNotHave]: {
    value: AutomationOperatorType.NotIn,
    label: 'Does Not Have',
  },
  [AutomationOperatorType.OwnsAny]: {
    value: AutomationOperatorType.In,
    label: 'Owns Any',
  },
  [AutomationOperatorType.OwnsAll]: {
    value: AutomationOperatorType.InAll,
    label: 'Owns All',
  },
  [AutomationOperatorType.DoesNotOwn]: {
    value: AutomationOperatorType.NotIn,
    label: 'Does Not Own',
  },
  [AutomationOperatorType.SpentMoreThan]: {
    value: AutomationOperatorType.GT,
    label: 'Spent More Than',
  },
  [AutomationOperatorType.SpentLessThan]: {
    value: AutomationOperatorType.LT,
    label: 'Spent Less Than',
  },
  [AutomationOperatorType.StartedAll]: {
    value: AutomationOperatorType.InAll,
    label: 'Started All',
  },
  [AutomationOperatorType.StartedAny]: {
    value: AutomationOperatorType.In,
    label: 'Started Any',
  },
  [AutomationOperatorType.StartedNone]: {
    value: AutomationOperatorType.NotIn,
    label: 'Started None',
  },
  [AutomationOperatorType.SubmittedAll]: {
    value: AutomationOperatorType.InAll,
    label: 'Submitted All',
  },
  [AutomationOperatorType.SubmittedAny]: {
    value: AutomationOperatorType.In,
    label: 'Submitted Any',
  },
  [AutomationOperatorType.SubmittedNone]: {
    value: AutomationOperatorType.NotIn,
    label: 'Submitted None',
  },
  [AutomationOperatorType.IsType]: {
    value: AutomationOperatorType.EQ,
    label: 'Is Type',
  },
  [AutomationOperatorType.IsNotType]: {
    value: AutomationOperatorType.NE,
    label: 'Is Not Type',
  },
  [AutomationOperatorType.EqualsAllChecked]: {
    value: AutomationOperatorType.InAll,
    label: 'Equals All Checked',
  },
  [AutomationOperatorType.EqualsAnyChecked]: {
    value: AutomationOperatorType.In,
    label: 'Equals Any Checked',
  },
  [AutomationOperatorType.EqualsNoneChecked]: {
    value: AutomationOperatorType.NotIn,
    label: 'Equals None Checked',
  },
  [AutomationOperatorType.Exists]: {
    value: AutomationOperatorType.Exists,
    label: 'Exists',
  },
  [AutomationOperatorType.DoesNotExist]: {
    value: AutomationOperatorType.DoesNotExist,
    label: 'Does Not Exist',
  },
  [AutomationOperatorType.OpenedAny]: {
    value: AutomationOperatorType.In,
    label: 'Opened Any',
  },
  [AutomationOperatorType.OpenedAll]: {
    value: AutomationOperatorType.InAll,
    label: 'Opened All',
  },
  [AutomationOperatorType.OpenedNone]: {
    value: AutomationOperatorType.NotIn,
    label: 'Opened None',
  },
  [AutomationOperatorType.ClickedAny]: {
    value: AutomationOperatorType.In,
    label: 'Clicked Any',
  },
  [AutomationOperatorType.ClickedAll]: {
    value: AutomationOperatorType.InAll,
    label: 'Clicked All',
  },
  [AutomationOperatorType.ClickedNone]: {
    value: AutomationOperatorType.NotIn,
    label: 'Clicked None',
  },
};
