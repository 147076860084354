import { EntityType } from '../../constants';

export interface AutomationConditionalFilterDefinition {
  name: string;
  target: string;
  operator: string;
  valueType?: string;
}

export enum AutomationConditionalFilterNames {
  Course = EntityType.Course,
  Challenge = EntityType.Challenge,
  Email = EntityType.Email,
  Campaign = EntityType.Campaign,
  CustomerTag = EntityType.CustomerTag,
  List = EntityType.CustomerList,
  Product = EntityType.Product,
  Form = EntityType.Form,
  FormCheckBox = 'FormCheckBox', // not an entity type
}

export enum AutomationConditionalFilterFields {
  CourseId = 'courseId',
  ChallengeId = 'challengeId',
  Email = 'email',
  Campaign = 'campaign',
  Tag = 'tag',
  List = 'list',
  Product = 'product',
  Form = 'form',
}

export enum AutomationFilterValueTypes {
  Lookup = 'lookup',
}

type ExcludeAutomationConditionalFilterNames =
  AutomationConditionalFilterNames.FormCheckBox;

export const AutomationConditionalFilters: Record<
  Exclude<
    AutomationConditionalFilterNames,
    ExcludeAutomationConditionalFilterNames
  >,
  AutomationConditionalFilterDefinition | undefined
> = {
  [AutomationConditionalFilterNames.Course]: {
    name: AutomationConditionalFilterNames.Course,
    target: AutomationConditionalFilterFields.CourseId,
    operator: '',
    valueType: AutomationFilterValueTypes.Lookup,
  },
  [AutomationConditionalFilterNames.Challenge]: {
    name: AutomationConditionalFilterNames.Challenge,
    target: AutomationConditionalFilterFields.ChallengeId,
    operator: '',
    valueType: AutomationFilterValueTypes.Lookup,
  },
  [AutomationConditionalFilterNames.Email]: {
    name: AutomationConditionalFilterNames.Email,
    target: AutomationConditionalFilterFields.Email,
    operator: '',
    valueType: AutomationFilterValueTypes.Lookup,
  },
  [AutomationConditionalFilterNames.Campaign]: {
    name: AutomationConditionalFilterNames.Campaign,
    target: AutomationConditionalFilterFields.Campaign,
    operator: '',
    valueType: AutomationFilterValueTypes.Lookup,
  },
  [AutomationConditionalFilterNames.CustomerTag]: {
    name: AutomationConditionalFilterNames.CustomerTag,
    target: AutomationConditionalFilterFields.Tag,
    operator: '',
    valueType: AutomationFilterValueTypes.Lookup,
  },
  [AutomationConditionalFilterNames.List]: {
    name: AutomationConditionalFilterNames.List,
    target: AutomationConditionalFilterFields.List,
    operator: '',
    valueType: AutomationFilterValueTypes.Lookup,
  },
  [AutomationConditionalFilterNames.Product]: {
    name: AutomationConditionalFilterNames.Product,
    target: AutomationConditionalFilterFields.Product,
    operator: '',
    valueType: AutomationFilterValueTypes.Lookup,
  },
  [AutomationConditionalFilterNames.Form]: {
    name: AutomationConditionalFilterNames.Form,
    target: AutomationConditionalFilterFields.Form,
    operator: '',
    valueType: AutomationFilterValueTypes.Lookup,
  },
};
