import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { createEffect, Actions, ofType, OnInitEffects } from '@ngrx/effects';
import { fetch, pessimisticUpdate } from '@ngrx/router-store/data-persistence';
import {
  DbService,
  CartItem,
  CartItemProperties,
  PromoCodeProperties,
  CartProperties,
} from '@sidkik/db';
import {
  APP_CONFIG,
  AppConfig,
  EntityType,
  EventsService,
} from '@sidkik/global';
import {
  combineLatest,
  defer,
  filter,
  map,
  mergeMap,
  of,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import * as CartActions from './cart.actions';
import { CartFacade } from './cart.facade';
import { ShopFacade } from '../shop.facade';

@Injectable()
export class CartEffects implements OnInitEffects {
  /** Navigation Actions */

  /** Fetch Actions */
  loadCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.loadCart),
      withLatestFrom(this.shopFacade.userId$),
      fetch({
        id: (
          a: ReturnType<typeof CartActions.loadCart>,
          userId: string | undefined
        ) => a.type + userId,
        run: (
          a: ReturnType<typeof CartActions.loadCart>,
          userId: string | undefined
        ) => {
          if (userId) {
            return this.dbService
              .getAllSubEntities<CartItem>(
                EntityType.CartItem,
                EntityType.CustomerParent,
                userId
              )
              .pipe(
                map((docs) => CartActions.loadCartSuccess({ items: docs }))
              );
          }
          return this.dbService.getDocsLocalOnly(EntityType.CartItem).pipe(
            map((docs) =>
              CartActions.loadCartSuccess({
                items: docs as CartItemProperties[],
              })
            )
          );
        },
        onError: (_, error) => {
          return null;
        },
      })
    )
  );

  /** Persistance Actions */

  addItemToCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.addCartItem),
      withLatestFrom(this.shopFacade.userId$, this.cartFacade.promoCode$),
      pessimisticUpdate({
        run: (
          a: ReturnType<typeof CartActions.addCartItem>,
          userId: string | undefined,
          promoCode: PromoCodeProperties | undefined
        ) => {
          if (userId) {
            return this.dbService
              .setDoc(a.item, EntityType.CustomerParent, userId)
              .pipe(
                map(() =>
                  CartActions.addCartItemSuccess({
                    item: a.item,
                  })
                )
              );
          }

          return this.dbService.setDocLocalOnly(a.item).pipe(
            map(() =>
              CartActions.addCartItemSuccess({
                item: a.item,
              })
            )
          );
        },
        onError: (a: ReturnType<typeof CartActions.addCartItem>, error) => {
          return CartActions.addCartItemFailure({
            item: a.item,
            error: error.message,
          });
        },
      })
    )
  );

  removeItemFromCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.deleteCartItem),
      withLatestFrom(this.shopFacade.userId$),
      pessimisticUpdate({
        run: (
          a: ReturnType<typeof CartActions.deleteCartItem>,
          userId: string | undefined
        ) => {
          if (userId) {
            return this.dbService
              .deleteDoc(a.item, EntityType.CustomerParent, userId)
              .pipe(
                map(() =>
                  CartActions.deleteCartItemSuccess({
                    item: a.item,
                    skipEvent: a.skipEvent,
                  })
                )
              );
          }
          return this.dbService.deleteDocLocalOnly(a.item).pipe(
            map(() =>
              CartActions.deleteCartItemSuccess({
                item: a.item,
                skipEvent: a.skipEvent,
              })
            )
          );
        },
        onError: (a: ReturnType<typeof CartActions.deleteCartItem>, error) => {
          return CartActions.deleteCartItemFailure({
            item: a.item,
            error: error.message,
          });
        },
      })
    )
  );

  loadCartSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.loadCartSuccess),
      map(() => CartActions.checkTerms())
    )
  );

  checkCouponOutcome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.checkCouponSuccess, CartActions.checkCouponFailure),
      map(() => CartActions.checkTerms())
    )
  );

  checkTerms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.checkTerms),
      mergeMap((a: ReturnType<typeof CartActions.checkTerms>) => {
        return combineLatest([
          this.cartFacade.allCartItemsWithDiscounts$,
          // this.cartFacade.promoCode$,
        ]).pipe(
          take(1),
          map(([cart]) => {
            if (!cart || cart.length === 0) {
              return CartActions.removeTerms();
            }
            // get the cart total
            const total = cart.reduce((acc, ci) => {
              if (ci.data?.sku.data.price) {
                return (
                  acc + ci.data.sku.data.price - (ci.data.discountAmount ?? 0)
                );
              }
              return acc;
            }, 0);

            // filter subscriptions
            const fCart = cart.filter(
              (ci) => ci.data?.sku.data.recurring?.period !== undefined
            );
            if (fCart.length === 0) {
              return CartActions.removeTerms();
            }

            let terms = `Your purchase contains a subscription. By submitting this order, you authorize "${
              this.appConfig.branding?.siteName ?? 'us'
            }" to use the provided payment method for the total cost of this order and any future recurring charges in accordance with our <a class="tw-underline" href="/terms-of-use" target="_blank">terms of use.</a>`;

            if (total === 0) {
              terms = ` We are collecting payment information today for future recurring payments on your
        subscription. By submitting this order, you authorize "${
          this.appConfig.branding?.siteName ?? 'us'
        }" to use the provided payment method for future recurring charges in accordance with our <a class="tw-underline" href="/terms-of-use" target="_blank">terms of use.</a>`;
            }

            return CartActions.updateTerms({
              terms,
              termsRequired: true,
            });
          })
        );
      })
    )
  );

  addItemToCartSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.addCartItemSuccess),
        tap((a: ReturnType<typeof CartActions.addCartItemSuccess>) => {
          combineLatest([
            this.cartFacade.allCartItems$,
            this.cartFacade.promoCode$,
          ])
            .pipe(take(1))
            .subscribe(([cart, pc]) => {
              this.eventsService.addToCart(a.item, cart);
              if (pc && cart) {
                const checkCart: Partial<CartProperties> = {
                  data: {
                    items: cart,
                  },
                };
                return this.cartFacade.checkDiscountCode(
                  pc.data.code,
                  checkCart as CartProperties
                );
              }
              return this.cartFacade.checkTerms();
            });
          // assumes coming from inside the products base
          this.router.navigate(['shop', 'cart']);
        })
      ),
    { dispatch: false }
  );

  deleteItemFromCartSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.deleteCartItemSuccess),
        tap((a: ReturnType<typeof CartActions.deleteCartItemSuccess>) => {
          combineLatest([
            this.cartFacade.allCartItems$,
            this.cartFacade.promoCode$,
          ])
            .pipe(take(1))
            .subscribe(([cart, pc]) => {
              if (!a.skipEvent) {
                this.eventsService.removeFromCart(a.item, cart);
              }
              if (pc && cart && cart.length > 0) {
                const checkCart: Partial<CartProperties> = {
                  data: {
                    items: cart,
                  },
                };
                this.cartFacade.checkDiscountCode(
                  pc.data.code,
                  checkCart as CartProperties
                );
              }
            });
          // assumes coming from inside the products base
          // this.router.navigate(['shop', 'cart']);
        })
      ),
    { dispatch: false }
  );

  /** General Actions */

  init$ = createEffect(() => defer(() => of(CartActions.loadCart())));

  /** Listen for other items */

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly cartFacade: CartFacade,
    private readonly shopFacade: ShopFacade,
    private readonly dbService: DbService,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private readonly eventsService: EventsService
  ) {}

  ngrxOnInitEffects() {
    this.shopFacade.userId$
      .pipe(
        filter((userId) => userId !== null && userId !== undefined),
        take(1)
      )
      .subscribe(() => this.cartFacade.loadCart());
    return CartActions.noop();
  }
}
