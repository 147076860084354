import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { PurchasableProperties } from '@sidkik/db';
import {
  AppConfig,
  APP_CONFIG,
  EventsService,
  ShopSections,
  ItemsDisplayType,
  DefaultShopItemsDisplayType,
  DefaultShopSections,
} from '@sidkik/global';
import { Observable, map, takeUntil } from 'rxjs';
import { ShopFacade } from '../../../+state/shop.facade';
import { MapFacade } from '../../../+state/map/map.facade';
import { BaseDestroyComponent } from '@sidkik/shared';

@Component({
  selector: 'sidkik-product-list',
  templateUrl: './product-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListComponent
  extends BaseDestroyComponent
  implements OnInit
{
  platforms$!: Observable<Partial<PurchasableProperties>[]>;
  membership$!: Observable<Partial<PurchasableProperties>[]>;
  session$!: Observable<Partial<PurchasableProperties>[]>;
  courseAndOrChallenge$!: Observable<Partial<PurchasableProperties>[]>;
  shopSections = ShopSections;
  itemsDisplayTypes = ItemsDisplayType;
  itemsDisplay$!: Observable<ItemsDisplayType>;
  items$!: Observable<ShopSections[]>;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private shopFacade: ShopFacade,
    private eventsService: EventsService,
    private mapFacade: MapFacade
  ) {
    super();
  }

  ngOnInit(): void {
    this.itemsDisplay$ = this.mapFacade.navMap$.pipe(
      map((nm) => {
        if (!nm) return DefaultShopItemsDisplayType;
        if (nm.data.shopItemsDisplayType) return nm.data.shopItemsDisplayType;
        return DefaultShopItemsDisplayType;
      })
    );
    this.items$ = this.mapFacade.navMap$.pipe(
      map((nm) => {
        if (!nm) return DefaultShopSections;
        if (nm.data.shopSections) return nm.data.shopSections;
        return DefaultShopSections;
      })
    );
    this.platforms$ = this.shopFacade.platformPurchasables$.pipe(
      map((items) =>
        items.filter(
          (item) =>
            item.data?.product?.data?.listed === true ||
            item.data?.product?.data?.listed === undefined
        )
      )
    );
    this.membership$ = this.shopFacade.membershipPurchasables$.pipe(
      map((items) =>
        items.filter(
          (item) =>
            item.data?.product?.data?.listed === true ||
            item.data?.product?.data?.listed === undefined
        )
      )
    );
    this.session$ = this.shopFacade.sessionPurchasables$.pipe(
      map((items) =>
        items.filter(
          (item) =>
            item.data?.product?.data?.listed === true ||
            item.data?.product?.data?.listed === undefined
        )
      )
    );
    this.courseAndOrChallenge$ =
      this.shopFacade.courseAndOrChallengePurchasables$.pipe(
        map((items) =>
          items.filter(
            (item) =>
              item.data?.product?.data?.listed === true ||
              item.data?.product?.data?.listed === undefined
          )
        )
      );

    this.shopFacade.allPotentialPurchasables$
      .pipe(takeUntil(this.destroy$))
      .subscribe((products) => {
        this.eventsService.viewProductList(products as PurchasableProperties[]);
      });

    this.eventsService.trackScreenView(
      'products listing',
      'shop/products/list'
    );
  }
}
