import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class BaseDestroyComponent implements OnDestroy {
  protected readonly destroy$ = new Subject<void>();

  ngOnDestroy(): void {
    // console.log(
    //   'track ngOnDestroy from base component  [',
    //   this.constructor.name,
    //   ']'
    // );
    this.destroy$.next();
    this.destroy$.complete();
  }
}
