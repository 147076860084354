@if ((readyToPurchase$ | async) && this.stripe)
{
<div>
  @if (total !== 0 || requiresFuturePaymentMethod && paymentElementOptions.mode === 'setup' && stripe) {
  <h2 id="payment-and-shipping-heading" class="tw-sr-only">Payment and shipping details</h2>
  <div class="tw-mx-auto tw-max-w-2xl tw-px-4 lg:tw-max-w-none lg:tw-px-0" ngxStripeElements
    [elementsOptions]="paymentElementOptions" [stripe]="stripe">
    <div class="">
      <h3 class="tw-text-lg tw-font-medium tw-text-gray-900">Payment details</h3>
      <div class="tw-mt-4">
        <ngx-stripe-payment (change)="onElementsChange($event)"
          [elementsOptions]="paymentElementOptions"></ngx-stripe-payment>
      </div>
    </div>

    <div class="tw-mt-10">
      <h3 class="tw-text-lg tw-font-medium tw-text-gray-900">Billing information</h3>
      <div class="tw-mt-4">
        <ngx-stripe-address [options]="addressElementOptions"
          [elementsOptions]="paymentElementOptions"></ngx-stripe-address>
      </div>
    </div>
    @if (termsRequired) {
    <div id="order-terms" class="tw-mt-10 tw-border-t tw-border-gray-200 tw-pt-6">
      <p class="tw-text-xs tw-text-gray-500" [innerHTML]="terms">{{ terms }}</p>
      <div class="tw-mt-3 tw-flex tw-justify-end tw-w-full">
        <sidkik-process-button data-testid="button-pay" [fullWidth]="false" [state]="state$ | async"
          (click)="createPaymentMethod()" processingText="Processing...">
          @if (total !== 0) {
          Pay now
          } @else {
          Submit order
          }
        </sidkik-process-button>
      </div>
    </div>
    } @else {
    <div id="order-terms" class="tw-mt-10 tw-flex tw-justify-end tw-border-t tw-border-gray-200 tw-pt-6">
      <sidkik-process-button data-testid="button-pay" [fullWidth]="false" [state]="state$ | async"
        (click)="createPaymentMethod()" processingText="Processing...">Pay
        now</sidkik-process-button>
    </div>
    }
  </div>
  } @else {
  <div id="order-terms" class="tw-mx-auto tw-max-w-2xl tw-px-4 lg:tw-max-w-none lg:tw-px-0">
    <h2 class="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900">This one is on the house!</h2>
    <p class="tw-mt-1 tw-text-sm tw-text-gray-500">Congratulations on getting free goodies.</p>
    <div class="tw-mt-10 tw-flex tw-justify-end tw-border-t tw-border-gray-200 tw-pt-6">
      <sidkik-process-button data-testid="button-pay-free" [fullWidth]="false" [state]="state$ | async"
        (click)="createPaymentMethod()" processingText="Processing...">Pay Nothing [FREE]</sidkik-process-button>
    </div>
  </div>
  }
</div>
} @else {
<div class="tw-text-center tw-mb-6">
  <svg class="tw-mx-auto tw-animate-spin tw-h-10 tw-w-10 tw-text-primary-500" viewBox="0 0 24 24">
    <circle class="tw-opacity-0" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="tw-opacity-75" fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
    </path>
  </svg>
  <h3 class="tw-mt-2 tw-text-sm tw-font-semibold tw-text-gray-900">Loading your information</h3>
  <p class="tw-mt-1 tw-text-sm tw-text-gray-500">{{ delayMessage$ | async}}</p>
</div>
}