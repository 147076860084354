/**
 * Decorator to automatically complete a Subject on ngOnDestroy
 * @param propertyKey - The property key of the Subject to complete
 * @returns The decorated class
 */
export function AutoDestroy() {
  return function (target: any, propertyKey: string) {
    if (!target.ngOnDestroy) {
      target.ngOnDestroy = function () {
        // console.log(
        //   'track AutoDestroy ngOnDestroy [',
        //   propertyKey,
        //   '] from [',
        //   target.constructor.name,
        //   ']'
        // );
        if (this[propertyKey]) {
          this[propertyKey].complete();
        }
      };
    } else {
      const originalDestroy = target.ngOnDestroy;
      target.ngOnDestroy = function () {
        // console.log(
        //   'track AutoDestroy ngOnDestroy [',
        //   propertyKey,
        //   '] from [',
        //   target.constructor.name,
        //   ']'
        // );
        if (this[propertyKey]) {
          this[propertyKey].complete();
        }
        originalDestroy.call(this);
      };
    }
  };
}
